<template>
  <span>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      :closable="closable"
      :mask-closable="maskClosable"
      destroy-on-close
      :class="{ 'is-full': isFit === true }"
      :mask="mask"
      :width="width"
    >
      <slot :hide="hide" />
    </a-modal>
    <slot name="handler" :show="show" />
  </span>
</template>

<script>
export default {
  name: 'ModalWide',
  props: {
    closable: {
      type: Boolean,
    },
    maskClosable: {
      type: Boolean,
    },
    mask: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '640px',
    },
    isFit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss">
@media (min-width: 800px) {
  .ant-modal.is-full {
    height: 100%;
    top: 0 !important;
    bottom: 0 !important;
    padding-bottom: 0;

    .ant-modal-body,
    .ant-modal-content {
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
