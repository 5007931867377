export const CALENDAR_STATUS = [
  { value: 'booked', label: 'booked' },
  { value: 'blocked', label: 'blocked' },
  { value: 'same-guest', label: 'same-guest' },
  { value: 'excess-guests', label: 'excess-guests' },
];

export const DATE_OPTIONS = [
  { value: 'checkin', label: 'checkin' },
  { value: 'checkout', label: 'checkout' },
  { value: 'agencyBookDate', label: 'agencyBookDate' },
];

export const STATUS = [
  { value: 'reserved', label: 'Reserved' },
  { value: 'cancelled', label: 'Cancelled' },
];

export const BASE_RESERVATION_COUNTER = 180;
