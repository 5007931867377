<template>
  <a-select
    v-model:value="id"
    show-search
    :placeholder="$t('website-name')"
    style="width: 100%"
    :default-active-first-option="false"
    :not-found-content="null"
    :size="size"
    @change="handleChange"
  >
    <a-select-option
      v-for="d in data"
      :key="d[returnValue]"
      :title="d.websiteName"
      :filter="d.websiteName"
    >
      {{ d.websiteName }}
    </a-select-option>
  </a-select>
</template>

<i18n>
{
  "en": {
    "website-name": "Website Name",
  },
  "ja": {
    "website-name": "サイト名",
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';

export default {
  name: 'InputTravelWebsite',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    returnValue: {
      type: String,
      default: 'websiteName',
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['change', 'update:value'],
  data() {
    return {
      id: undefined,
      data: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (this.returnValue === 'id') {
          this.id = nv ? parseInt(nv, 10) : undefined;
        } else {
          this.id = nv || undefined;
        }
      },
    },
  },
  created() {
    this.handleSearch();
  },
  methods: {
    handleSearch() {
      return this.$store.dispatch('rms-toplines/listTravelWebsites').then((res) => {
        this.data = res.data;
      });
    },
    delaySearch: debounce(function delaySearch(value) {
      this.handleSearch(value);
    }, 600),
    handleChange(value) {
      this.id = value;
      this.$emit('change', this.id);
      this.$emit('update:value', this.id);
    },
  },
};
</script>
