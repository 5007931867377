<template>
  <a-input-group size="large">
    <a-auto-complete
      id="autocomplete_tw"
      v-model:value="travelWebsite"
      :options="data"
      style="width: 100%;"
      @change="handleChange"
    />
  </a-input-group>
</template>

<script>
export default {
  name: 'AutocompleteTravelWebsite',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      data: [],
    };
  },
  computed: {
    travelWebsite: {
      get() {
        return this.value;
      },
      set(val) {
        this.handleChange(val);
      },
    },
  },
  created() {
    this.$store.dispatch('rms-toplines/listTravelWebsites').then((res) => {
      if (res.data) {
        this.data = res.data.map((tw) => ({ value: tw.websiteName }));
      }
    });
  },
  methods: {
    handleChange(value) {
      this.$emit('update:value', value);
      this.$emit('change', value);
    },
  },
};
</script>

<style>
input#autocomplete_tw {
  height: 100% !important;
}
</style>
