<template>
  <a-form
    ref="form"
    :model="form"
  >
    <div class="columns is-multiline">
      <div class="column is-12">
        <h3 class="has-text-primary has-text-weight-bold is-size-4">
          {{ title }}
        </h3>
      </div>
      <div class="column is-6">
        <div class="columns is-mini-gap is-multiline">
          <div class="column columns-table is-12 is-gapless is-mobile is-size-7 m-b-0">
            <div class="th">
              {{ $t('Property') }}
            </div>
          </div>
          <a-form-item class="column is-12" :label="$t('Property Name')" name="propertyName">
            <a-input v-model:value="form.propertyName" :size="size" />
          </a-form-item>
          <a-form-item class="column" :label="$t('Property Code')" name="propertyCode">
            <a-input v-model:value="form.propertyCode" :size="size" />
          </a-form-item>
          <a-form-item class="column" :label="$t('Room Number')" name="roomNumber">
            <a-input v-model:value="form.roomNumber" :size="size" />
          </a-form-item>
        </div>
        <div class="columns is-mini-gap is-multiline">
          <div class="column columns-table is-12 is-gapless is-mobile is-size-7 m-b-0">
            <div class="th">
              {{ $t('Info') }}
            </div>
          </div>
          <a-form-item class="column is-6" :label="$t('Checkin Date')" name="checkin">
            <a-date-picker
              v-model:value="form.checkin"
              :value-format="dateFormat"
              :size="size"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Checkout Date')" name="checkout">
            <a-date-picker
              v-model:value="form.checkout"
              :value-format="dateFormat"
              :size="size"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Number of Guests')" name="numOfGuests">
            <a-input-number
              v-model:value="form.numOfGuests"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Remarks')" name="remarks">
            <a-input v-model:value="form.remarks" :size="size" />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Number of Nights')" name="numOfNights">
            <a-input-number
              v-model:value="form.numOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('1 Person Number of Nights')"
            name="onePersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.onePersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('2 Person Number of Nights')"
            name="twoPersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.twoPersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('3 Person Number of Nights')"
            name="threePersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.threePersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('4 Person Number of Nights')"
            name="fourPersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.fourPersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('5 Person Number of Nights')"
            name="fivePersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.fivePersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('6 Person Number of Nights')"
            name="sixPersonNumOfNights"
          >
            <a-input-number
              v-model:value="form.sixPersonNumOfNights"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('1 Night Total')"
            name="oneNightTotal"
          >
            <a-input-number
              v-model:value="form.oneNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('2 Night Total')"
            name="twoNightTotal"
          >
            <a-input-number
              v-model:value="form.twoNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('3 Night Total')"
            name="threeNightTotal"
          >
            <a-input-number
              v-model:value="form.threeNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('4 Night Total')"
            name="fourNightTotal"
          >
            <a-input-number
              v-model:value="form.fourNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('5 Night Total')"
            name="fiveNightTotal"
          >
            <a-input-number
              v-model:value="form.fiveNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('6 Night Total')"
            name="sixNightTotal"
          >
            <a-input-number
              v-model:value="form.sixNightTotal"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
        </div>
      </div>
      <div class="column is-6">
        <div class="columns is-mini-gap is-multiline">
          <div class="column columns-table is-12 is-gapless is-mobile is-size-7 m-b-0">
            <div class="th">
              {{ $t('Reservation') }}
            </div>
          </div>
          <a-form-item
            class="column is-4"
            :label="$t('Reservation Number')"
            name="reservationNumber"
          >
            <a-input v-model:value="form.reservationNumber" :size="size" />
          </a-form-item>
          <a-form-item
            class="column is-8"
            :label="$t('External Website Reservation ID')"
            name="externalWebsiteReservationId"
          >
            <a-input v-model:value="form.externalWebsiteReservationId" :size="size" />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Reservation Date')" name="reservationDate">
            <a-date-picker
              v-model:value="form.reservationDate"
              :value-format="dateFormat"
              :size="size"
              style="width: 100%"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Status')" name="status">
            <a-select
              v-model:value="form.status"
              :placeholder="$t('Status')"
              :default-active-first-option="false"
              :size="size"
            >
              <a-select-option
                v-for="(st, id) in reservationsStatuses"
                :key="id"
                :value="st.value"
                :title="$t(st.label)"
              >
                {{ $t(st.label) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('Website Name')" name="websiteName">
            <AutocompleteTravelWebsite v-model:value="form.websiteName" />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('OTA Website Code')" name="otaWebsiteCode">
            <a-input
              v-model:value="form.otaWebsiteCode" :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('External Website')"
            name="externalWebsite"
          >
            <AutocompleteTravelWebsite v-model:value="form.externalWebsite" />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('External Website OTA Code')"
            name="externalWebsiteOtaCode"
          >
            <a-input v-model:value="form.externalWebsiteOtaCode" :size="size" />
          </a-form-item>
          <a-form-item
            class="column is-6" :label="$t('Sales')" name="totalSalesAmount"
          >
            <a-input-number
              v-model:value="form.totalSalesAmount"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('OTA Fees')" name="otaFees">
            <a-input-number
              v-model:value="form.otaFees"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item class="column is-6" :label="$t('OTA Fee Rates')" name="otaFeeRates">
            <a-input
              v-model:value="form.otaFeeRates"
              type="number"
              suffix="%"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
          <a-form-item
            class="column is-6"
            :label="$t('Sales without OTA Fees')"
            name="salesWithoutOtaFees"
          >
            <a-input-number
              v-model:value="form.salesWithoutOtaFees"
              style="width: 100%;"
              :size="size"
            />
          </a-form-item>
        </div>
      </div>
    </div>

    <div class="column is-12 m-t-4x">
      <div class="is-flex is-justify-content-space-between">
        <div style="min-width: 100px">
          <div v-if="value && value.id">
            <DeleteTopline :record="value" @delete="$emit('close')">
              <template #handler="{ show }">
                <a-button
                  :size="size"
                  type="danger"
                  @click="show"
                >
                  {{ $t('Delete') }}
                </a-button>
              </template>
            </DeleteTopline>
          </div>
        </div>
        <div>
          <a-button
            :disabled="loading"
            :size="size"
            class="button-secondary-outline m-r-3x"
            style="min-width: 115px"
            @click="resetFields"
          >
            {{ $t('Cancel') }}
          </a-button>
          <a-button
            :loading="loading"
            :size="size"
            style="min-width: 115px"
            type="primary"
            @click="handleSubmit"
          >
            {{ $t('Save') }}
          </a-button>
        </div>
      </div>
    </div>
  </a-form>
</template>

<i18n>
{
  "en": {
    "Website Name": "Website Name",
    "add-topline": "Add Topline",
    "Info": "Info",
    "Number of Guests": "Number of Guests",
    "Remarks": "Remarks",
    "Number of Nights": "Number of Nights",
    "1 Person Number of Nights": "1 Person Number of Nights",
    "2 Person Number of Nights": "2 Person Number of Nights",
    "3 Person Number of Nights": "3 Person Number of Nights",
    "4 Person Number of Nights": "4 Person Number of Nights",
    "5 Person Number of Nights": "5 Person Number of Nights",
    "6 Person Number of Nights": "6 Person Number of Nights",
    "1 Night Total": "1 Night Total",
    "2 Night Total": "2 Night Total",
    "3 Night Total": "3 Night Total",
    "4 Night Total": "4 Night Total",
    "5 Night Total": "5 Night Total",
    "6 Night Total": "6 Night Total",
    "External Website Reservation ID": "External Website Reservation ID",
    "Reservation Date": "Reservation Date",
    "OTA Website Code": "OTA Website Code",
    "External Website": "External Website",
    "External Website OTA Code": "External Website OTA Code",
    "OTA Fees": "OTA Fees",
    "OTA Fee Rates": "OTA Fee Rates",
    "Sales without OTA Fees": "Sales without OTA Fees",
    "Sales": "Sales",
    "Checkin Date": "Checkin Date",
    "Edit Topline": "Edit Topline",
  },
  "ja": {
    "Website Name": "サイト名",
    "add-topline": "トップラインを追加",
    "Info": "情報",
    "Number of Guests": "宿泊人数",
    "Remarks": "備考(楽トラ/Booking用)",
    "Number of Nights": "宿泊数",
    "1 Person Number of Nights": "１名泊数",
    "2 Person Number of Nights": "２名泊数",
    "3 Person Number of Nights": "３名泊数",
    "4 Person Number of Nights": "４名泊数",
    "5 Person Number of Nights": "５名泊数",
    "6 Person Number of Nights": "６名以上泊数",
    "1 Night Total": "１泊件数",
    "2 Night Total": "２泊件数",
    "3 Night Total": "３泊件数",
    "4 Night Total": "４泊件数",
    "5 Night Total": "５泊件数",
    "6 Night Total": "６泊以上件数",
    "External Website Reservation ID": "外部予約ID",
    "Reservation Date": "予約日",
    "OTA Website Code": "OTAサイトコード",
    "External Website": "外部予約サイト",
    "External Website OTA Code": "外部予約OTAサイトコード",
    "OTA Fees": "OTA手数料",
    "OTA Fee Rates": "OTA手数料率",
    "Sales without OTA Fees": "OTA手数料抜き売上",
    "Sales": "売上",
    "Checkin Date": "チェックイン日",
    "Edit Topline": "トップラインを編集",
  },
}
</i18n>

<script>
import { STATUS } from '@/config/reservations';
import DeleteTopline from './DelTopline';
import AutocompleteTravelWebsite from './InputAutocompleteTravelWebsite';

export default {
  name: 'AddToplineForm',
  components: {
    DeleteTopline,
    AutocompleteTravelWebsite,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['cancel', 'close'],
  data() {
    return {
      reservationsStatuses: STATUS,
      dateFormat: 'YYYY-MM-DD',
      size: 'large',
      form: {
        propertyName: undefined,
        propertyCode: undefined,
        checkin: undefined,
        checkout: undefined,
        numOfGuests: undefined,
        remarks: undefined,
        numOfNights: undefined,
        reservationNumber: undefined,
        externalWebsiteReservationId: undefined,
        reservationDate: undefined,
        status: undefined,
        websiteName: undefined,
        otaWebsiteCode: undefined,
        externalWebsite: undefined,
        externalWebsiteOtaCode: undefined,
        totalSalesAmount: undefined,
        salesWithoutOtaFees: undefined,
        otaFees: undefined,
        otaFeeRates: undefined,
        numOfRoom: undefined,
        roomNumber: undefined,
        roomCode: undefined,
        commissionLiftUp: undefined,
        onePersonNumOfNights: undefined,
        twoPersonNumOfNights: undefined,
        threePersonNumOfNights: undefined,
        fourPersonNumOfNights: undefined,
        fivePersonNumOfNights: undefined,
        sixPersonNumOfNights: undefined,
        oneNightTotal: undefined,
        twoNightTotal: undefined,
        threeNightTotal: undefined,
        fourNightTotal: undefined,
        fiveNightTotal: undefined,
        sixNightTotal: undefined,
        toplineStatus: undefined,
      },
    };
  },
  computed: {
    title() {
      return this.value && this.value.id ? this.$t('Edit Topline') : this.$t('add-topline');
    },
  },
  watch: {
    value: {
      handler(nv) {
        if (nv) {
          const keys = Object.keys(this.form);
          keys.forEach((key) => {
            this.form[key] = nv[key];
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleSubmit() {
      let method = 'rms-toplines/create';
      const data = { ...this.form };
      if (this.value && this.value.id) {
        method = 'rms-toplines/update';
        data.id = this.value.id;
      }

      this.$store.dispatch(method, data)
        .then(() => {
          this.$emit('close');
          this.resetFields();
        });
    },
    resetFields() {
      this.$refs.form.resetFields();
      this.$emit('cancel');
    },
  },
};
</script>
